<template>
  <div
    class="topbar__menu"
    @click="handleClick"
  >
    <div class="topbar__menu__dropdown-container">
      <div class="topbar__menu__disk">
        <div class="topbar__menu__disk-text">
          {{ initials }}
        </div>
      </div>

      <ArrowDown
        v-if="dropdownVisible"
        class="topbar__menu__arrow--up"
      />

      <ArrowDown
        v-else
        class="topbar__menu__arrow--down"
      />
    </div>

    <div
      v-if="dropdownVisible"
      class="topbar__menu__dropdown"
    >
      <div class="topbar__menu__dropdown-interior">
        <slot name="my-account" />
        
        <slot name="logout" />
      </div>
    </div>
  </div>
</template>

<script>
import ArrowDown from './svg/ArrowDown.vue'

export default {
  name: 'NavLoggedInMenu',

  components: {
    ArrowDown
  },

  props: {
    initials: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      dropdownVisible: false
    }
  },

  methods: {
    handleClick () {
      this.triggerDropdown()
    },

    triggerDropdown () {
      this.dropdownVisible = !this.dropdownVisible
    }
  }
}
</script>
