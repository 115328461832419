<template>
  <div class="project-listing__hero">
    <div class="project-listing__hero-inner">
      <div class="project-listing__hero-left">
        <h1
          class="project-listing__name"
          v-text="developerName"
        />
      </div>
      <div class="project-listing__hero-right">
        <img
          class="project-listing__project-icon project-listing__project-icon--left"
          :src="iconDeveloperUrl"
        >
        <img
          class="project-listing__project-icon project-listing__project-icon--middle"
          :src="iconDeveloperUrl"
        >
        <img
          class="project-listing__project-icon project-listing__project-icon--right"
          :src="iconDeveloperUrl"
        >
      </div>
    </div>
    <div class="project-listing__hero-tabs">
      <a
        v-for="project in developerProjects"
        :key="project.id"
        :href="project.path"
        class="project-listing__hero-tab"
        :class="{ 'project-listing__hero-tab--current': isCurrentTab(project.id) }"
        v-text="project.name"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectListingHero',

  props: {
    developerName: {
      type: String,
      required: true
    },

    developerProjects: {
      type: Array,
      default: () => []
    },

    iconArrowGreyLeftUrl: {
      type: String,
      required: true
    },

    iconDeveloperUrl: {
      type: String,
      required: true
    },

    id: {
      type: String,
      required: true
    }
  },

  methods: {
    isCurrentTab(projectId) {
      return projectId === parseInt(this.id, 10)
    }
  }
}
</script>