<template>
  <div class="hero-sign-up">
    <div class="hero-sign-up__inner">
      <h1
        class="hero-sign-up__title"
        v-text="title"
      />

      <div
        class="hero-sign-up__subtitle"
        v-html="subtitle"
      />
      
      <img
        :src="imageUrl"
        class="hero-sign-up__image"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserSignUpHero',

  props: {
    imageUrl: {
      type: String,
      required: true
    },

    subtitle: {
      type: String,
      required: true
    },

    title: {
      type: String,
      required: true
    }    
  },
}
</script>