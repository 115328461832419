// roleEnumerableMap. Must conform to the enumberables found in models/user.rb
export const roleEnumerableMap = {
  'developer': 1,
  // 'investor': 2,
  'buyer': 3
}

// An array form of the above.
// It's intentionally generated from the map above for single source of truth.
export const roleArray = Object.keys(roleEnumerableMap)
