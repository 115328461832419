<template>
  <div class="buyer-profile-listing">
    <BuyerProfileListingHero
      v-bind="{
        iconArrowGreyLeftUrl,
        iconBuyerUrl,
        name: config.name
      }"
    />
    <ListingMain
      v-bind="{
        actionBarProps: {
          contactText: config.contactText,
          email: config.email,
          emailVisible: config.emailVisible,
          iconSavedUrl,
          iconUnsavedUrl,
          id: config.id,
          presaved: config.presaved,
          saveable: config.saveable,
          saveAction: config.saveAction,
          saveData: config.saveData,
          userCreatedAt: config.userCreatedAt
        },
        dataMainSections: config.profileSections
      }"
    >
      <template #dataSide>
        <div class="listing-data__side-item">
          <h4>Company name</h4>
          <span v-text="config.companyName" />
        </div>
        <div
          v-if="config.interests.length"
          class="listing-data__side-item"
        >
          <h4>Interests</h4>
          <ul>
            <li
              v-for="(interest, interestIndex) in config.interests"
              :key="interestIndex"
              v-text="interestItemText(interest)"
            />
          </ul>
        </div>
      </template>
    </ListingMain>
  </div>
</template>

<script>
import BuyerProfileListingHero from './listing/Hero'
import ListingMain from '../listing/Main'

export default {
  name: 'BuyerProfileListing',

  components: {
    BuyerProfileListingHero,
    ListingMain,
  },

  props: {
    config: {
      type: Object,
      required: true
    },

    iconArrowGreyLeftUrl: {
      type: String,
      required: true,
    },

    iconBuyerUrl: {
      type: String,
      required: true,
    },

    iconSavedUrl: {
      type: String,
      required: true,
    },

    iconUnsavedUrl: {
      type: String,
      required: true,
    }
  },

  methods: {
    interestItemText (interest) {
      return `• ${interest}`
    }
  }
}
</script>