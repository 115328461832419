<template>
  <div class="form-action-bar">
    <div class="form-action-bar__side">
      <button
        v-if="isFirstPage"
        class="form-action-bar__item form-action-bar__item--exit"
        @click.prevent="saveAndExit"
      >
        Save and exit
      </button>
      <button
        v-else
        class="form-action-bar__item form-action-bar__item--navigation"
        @click.prevent="previousPage"
      >
        &lt;&lt; Previous
      </button>
    </div>
    <div class="form-action-bar__side form-action-bar__side--right">
      <button
        v-if="!isFirstPage"
        class="form-action-bar__item form-action-bar__item--exit"
        @click.prevent="saveAndExit"
      >
        Save and exit
      </button>
      <button
        v-if="isLastPage"
        class="form-action-bar__item form-action-bar__item--publish"
        @click.prevent="publish"
      >
        Publish
      </button>
      <button
        v-else
        class="form-action-bar__item form-action-bar__item--navigation"
        @click.prevent="nextPage"
      >
        Next &gt;&gt;
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectSurveyActionBar',

  props: {
    isFirstPage: {
      type: Boolean,
      default: true
    },

    isLastPage: {
      type: Boolean,
      default: false
    },

    nextPage: {
      type: Function,
      required: true
    },

    previousPage: {
      type: Function,
      required: true
    },

    publish: {
      type: Function,
      required: true
    },

    saveAndExit: {
      type: Function,
      required: true
    }
  }
}
</script>