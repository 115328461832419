<template>
  <div class="listing-table">
    <div
      v-if="!headerless"
      class="listing-table__head"
    >
      <div
        v-for="(column, columnIndex) in columns"
        :key="columnIndex"
        class="listing-table__header"
        v-text="column.header"
      />
    </div>
    <div class="listing-table__body">
      <div
        v-for="(rowData, rowIndex) in data"
        :key="rowIndex"
        class="listing-table__data-row"
      >
        <div
          v-for="(cellData, cellIndex) in rowData"
          :key="cellIndex"
          class="listing-table__data"
          :class="{ 'listing-table__data--highlighted': isHighlightedColumn(cellIndex) }"
          v-text="cellData"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListingTable',

  props: {
    columns: {
      type: Array,
      default: () => []
    },

    data: {
      type: Array,
      default: () => []
    },

    headerless: {
      type: Boolean,
      required: true
    }
  },

  methods: {
    isHighlightedColumn (columnIndex) {
      return this.columns[columnIndex].highlight
    }
  }
}
</script>