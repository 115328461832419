// Active Storage/S3
import { DirectUpload } from '@rails/activestorage'

const cleanBooleanCheckboxHandling = (surveyData, booleanCheckboxFields) => {
  if (!booleanCheckboxFields) return surveyData

  return booleanCheckboxFields.reduce((accumulator, current) => {
    // if the boolean field is in the data, set the value to true, else false
    const boolean = accumulator[current] ? true : false

    return { ...accumulator, [current]: boolean }
  }, { ...surveyData })
}

const cleanFileHandling = (uploadedFiles, surveyData) => {
  const data = { ...surveyData }

  // for each key in this.uploadedFiles, check if it's has many, then return
  // an array of signed IDs if so, or a single signed ID string if not
  Object.keys(uploadedFiles).forEach(questionName => {
    if (uploadedFiles[questionName].hasMany) {
      data[questionName] = uploadedFiles[questionName].files.map(file => file.signed_id)
    } else {
      const file = uploadedFiles[questionName].files[0]
      const signedId = file ? file.signed_id : ''

      data[questionName] = signedId
    }
  })

  return data
}

const cleanMatrixHandling = (surveyData, matrixFields) => {
  if (!matrixFields) return surveyData

  return matrixFields.reduce((accumulator, current) => {
    const attributes = surveyData[current.field]

    if (attributes) {
      const cleanAttributes = Object.keys(attributes).map(rowId => {
        return {
          [current.row]: rowId,
          [current.column]: attributes[rowId]
        }
      })
      
      return { ...accumulator, [current.field]: cleanAttributes}
    } else return accumulator
  }, { ...surveyData })
}

const cleanMultipleTextAttributesHandling = (surveyData, multipleTextAttributesFields) => {
  if (!multipleTextAttributesFields) return surveyData

  return multipleTextAttributesFields.reduce((accumulator, current) => {
    const arrayWithAttributes = surveyData[current.field]

    if (arrayWithAttributes) {
      // if the attributes field is in the data, get the first element of the
      // array it points to, then with each id key within, restructure the data
      // so that the id is labelled as the appropriate foreign key
      const attributes = arrayWithAttributes[0]

      const cleanAttributes = Object.keys(attributes).map(id => {
        const attribute = attributes[id]

        // this will only work for simple multiple text nested attributes fields,
        // i.e. where they belong to two models - one being the form's main model
        // and the other being a 'type' model, e.g. composition type
        return { [current.typeField]: id, ...attribute }
      })

      return { ...accumulator, [current.field]: cleanAttributes}
    } else return accumulator
  }, { ...surveyData })
}

const cleanNoneHandling = (surveyData, noneFields) => {
  if (!noneFields) return surveyData

  return noneFields.reduce((accumulator, current) => {
    const { field, noneId } = current

    // if the field's value is an array containing 'none', set it to an array
    // containing the ID of the field's none record
    if (accumulator[field] && accumulator[field][0] === 'none') {
      return { ...accumulator, [field]: [noneId] }
    } else return accumulator
  }, { ...surveyData })
}

const cleanOtherHandling = (surveyData, otherFields) => {
  if (!otherFields) return surveyData

  return otherFields.reduce((accumulator, current) => {
    const { modelOtherField, surveyField } = current

    const fieldData = accumulator[surveyField]

    // return object as is if the field is not present
    if (!fieldData) return accumulator

    const next = { ...accumulator }

    // add a new key as per modelOtherField and set it equal to the current
    // other field/survey comment field, which is the ID(s) field + '-Comment'
    // then delete the survey comment field
    next[modelOtherField] = next[surveyField + '-Comment']
    delete next[surveyField + '-Comment']
      
    // skip remaining cleaning if the field is not/does not contain 'other'
    const isArray = Array.isArray(fieldData)
    const arrayWithoutOther = isArray && !fieldData.includes('other')
    const nonArrayNotOther = !isArray && fieldData !== 'other'
    
    if (arrayWithoutOther || nonArrayNotOther) { return next }

    // if fieldData is a string, delete the key altogether
    // else (for arrays) filter out the 'Other' string
    if (isArray) {
      const newArray = fieldData.filter(id => id !== 'other')
      
      next[surveyField] = newArray
    }
    else delete next[surveyField]

    return next
  }, { ...surveyData })
}

// expectation: component has `uploadedFiles` object and `uploadingFiles` boolean
// in state (`data`)
export const processFileUpload = (component, upload) => {
  // tell component that files are uploading
  component.uploadingFiles = true

  // get data from upload object (SurveyJS options object)
  const { files } = upload
  const questionName = upload.name
  const hasMany = upload.question.allowMultiple

  files.forEach((file, fileIndex) => {
    // create direct upload object for uploading to S3
    const directUpload = new DirectUpload(file, '/rails/active_storage/direct_uploads')

    // initiate upload to S3
    directUpload.create((error, blob) => {
      if (error) {
        console.error(error)
      } else {
        // if the question doesn't yet have a corresponding key in
        // component.uploadedFiles, create it
        if (!component.uploadedFiles[questionName]) {
          component.uploadedFiles[questionName] = {
            hasMany,
            files: []
          }
        }
        
        // if the field is not has many, remove the existing file
        // information from this.uploadedFiles
        if (!hasMany) {
          component.uploadedFiles[questionName].files.pop()
        }

        // add the new file information
        component.uploadedFiles[questionName].files.push(blob)

        // set content of file in upload to the uploaded file URL
        const fileUrl = `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`

        file.content = fileUrl

        // callback
        upload.callback(
          'success', [
            {
              file,
              content: file.content
            }
          ]
        )
      }

      // tell component that file upload is complete (or errored) after last file is processed
      const isLastFile = fileIndex === files.length - 1

      if (isLastFile) {
        component.uploadingFiles = false
      }
    })
  })
}

// expectation: component has `uploadedFiles` object in state (`data`)
export const processFileRemoval = (component, removalData) => {
  const questionName = removalData.name 
  const hasMany = removalData.question.allowMultiple
  const fileToRemove = removalData.fileName
  
  // fileName is null when remove all action is triggered
  const removeAll = !fileToRemove

  // value is undefined when this is called before uploading an initial file to
  // a single file input
  const existingData = !!removalData.value

  // only process removal if data exists
  if (existingData) {
    // process single file inputs and calls to remove all data
    if (!hasMany || removeAll) {
      // remove file(s) from component upload record (to be processed on submit)
      component.uploadedFiles[questionName].files = []
  
      // remove file(s) from SurveyJS data
      component.survey.mergeData({[questionName]: []})
    } else {
      // process removal of one file from a multiple file input

      // find signed ID of file to remove
      const fileInSurveyData = removalData.value.find(file => file.name === fileToRemove)
      const blobPath = fileInSurveyData.content
      const signedId = blobPath.match(/[^/]+/g)[3]
  
      // filter file out of component.uploadedFiles
      const filteredUploadedFiles = component.uploadedFiles[questionName].files.filter(uploadedFile => {
        return uploadedFile.signed_id !== signedId
      })
      
      component.uploadedFiles[questionName].files = filteredUploadedFiles
  
      // filter file out of survey data
      const filteredSurveyQuestionData = removalData.value.filter(file => file.name !== fileToRemove)
  
      component.survey.mergeData({[questionName]: filteredSurveyQuestionData})
    }
  }

  // respond with success - onClearFiles is called before onUploadFiles
  // in single file inputs and this callback is necessary to let it know to
  // continue to onUploadFiles
  removalData.callback('success')
}

export const removeFileOnQuestionHidden = (survey, removalData) => {
  const isFileInput = removalData.question.constructor.name === 'QuestionFileModel'

  if (!isFileInput) return

  const questionName = removalData.name
  const hasData = !!survey.data[questionName]

  if (hasData) {
    // https://surveyjs.io/Documentation/Library?id=surveymodel#clearFiles
    survey.clearFiles(
      removalData.question,
      questionName,
      true,
      null,
      () => {}
    )
  }
}

export const retainNullValues = (survey, options, config) => {
  const questionName = options.name
  const newValue = options.value

  // booleans, numbers and other field comments - return since all values should be valid
  const skippableTypes = ['boolean', 'number']
  const isSkippableType = skippableTypes.includes(typeof newValue)

  if (isSkippableType) return

  // objects
  if (typeof newValue === 'object') {
    // arrays
    if (!newValue.length && Array.isArray(newValue)) {
      survey.mergeData({[questionName]: []})
    }
  // strings
  } else if (!newValue) {
    survey.mergeData({[questionName]: ''})

    // other comments
    const otherSurveyCommentFields = config.otherFields.map(otherField => {
      return otherField.surveyField + '-Comment'
    })
    
    if (otherSurveyCommentFields.includes(questionName)) {
      const otherField = questionName.replace('-Comment', '')
      const otherFieldData = survey.data[otherField]

      // has many
      if (Array.isArray(otherFieldData)) {
        const otherFieldDataWithoutOther = otherFieldData.filter(item => item !== 'other')
  
        survey.mergeData({[otherField]: otherFieldDataWithoutOther})
      // belongs to
      } else {
        const newBelongsToId = options.question.cachedValueForUrlRequests

        survey.mergeData({[otherField]: newBelongsToId})
      }
    }
  }
}

export const setExistingNestedAttributes = (survey, config) => {
  if (!config.nestedAttributes) return

  config.nestedAttributes.forEach(nestedAttributesType => {
    const { data, field } = nestedAttributesType

    if (data) {
      survey.mergeData({[field]: data})
    }
  })
}

export const setExistingOtherData = (survey, config) => {
  if (!config.otherFields) return

  config.otherFields.forEach(otherField => {
    const { data, hasMany, surveyField } = otherField

    // exit if there's no existing other data
    if (!data) { return false }

    // add 'other' to the ID(s) field value
    if (hasMany) {
      // add other to existing or new array
      if (survey.data[surveyField]) {
        survey.data[surveyField].push('other')
      } else {
        survey.mergeData({[surveyField]: ['other']})
      }
    } else {
      // set field to 'other'
      survey.mergeData({[surveyField]: 'other'})
    }

    // set the other text to the existing data
    const otherTextField = surveyField + '-Comment'

    survey.mergeData({[otherTextField]: data})
  })
}

// expectation: component has `uploadedFiles` object in state (`data`)
export const setExistingUploadedFiles = (component, config) => {
  component.uploadedFiles = config.uploadedFiles
}

export const submit = (surveyData, config, axios, uploadedFiles = null, errorCallback = undefined) => {
  const { action, key, method } = config.axios

  let cleanSurveyData = cleanBooleanCheckboxHandling(surveyData, config.booleanCheckboxFields)

  if (uploadedFiles) {
    cleanSurveyData = cleanFileHandling(uploadedFiles, cleanSurveyData)
  }

  cleanSurveyData = cleanMatrixHandling(cleanSurveyData, config.matrixFields)
  cleanSurveyData = cleanMultipleTextAttributesHandling(cleanSurveyData, config.multipleTextAttributesFields)
  cleanSurveyData = cleanNoneHandling(cleanSurveyData, config.noneFields)
  cleanSurveyData = cleanOtherHandling(cleanSurveyData, config.otherFields)

  const data = { [key]: cleanSurveyData }

  axios(action, { method, data })
    .then(() => window.location = '/dashboard')
    .catch(error => {
      if (errorCallback) {
        errorCallback(error)
      } else {
        console.error(error)
      }
    })
}