<template>
  <div class="buyer-profile-listing__hero">
    <div class="buyer-profile-listing__hero-inner">
      <div class="buyer-profile-listing__hero-left">
        <h1
          class="buyer-profile-listing__name"
          v-text="name"
        />
      </div>
      <div class="buyer-profile-listing__hero-right">
        <img
          class="buyer-profile-listing__buyer-icon buyer-profile-listing__buyer-icon--left"
          :src="iconBuyerUrl"
        >
        <img
          class="buyer-profile-listing__buyer-icon buyer-profile-listing__buyer-icon--right"
          :src="iconBuyerUrl"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BuyerProfileListing',

  props: {
    iconArrowGreyLeftUrl: {
      type: String,
      required: true,
    },

    iconBuyerUrl: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    }
  }
}
</script>