<template>
  <div class="listing-action-bar">
    <div class="listing-action-bar__left listing-action-bar__left--no-email">
      <a
        v-if="emailVisible"
        class="listing-action-bar__contact-button"
        :href="contactLink"
        target="_blank"
        v-text="contactText"
      />
      <div
        class="listing-action-bar__created_at"
        v-text="userCreatedAtText"
      />
    </div>
    <div
      v-if="saveable"
      class="listing-action-bar__right"
    >
      <button
        class="listing-action-bar__save-button"
        @click="toggleSavedState"
      >
        <img
          class="listing-action-bar__save-button-icon"
          :src="saveButtonIconUrl"
        >
      </button>
    </div>
  </div>
</template>

<script>
// for date formatting
import moment from 'moment'

// Axios
import axios from 'axios'
import { setAxiosHeaders } from '../../helpers/setAxiosHeaders'

export default {
  name: 'ListingActionBar',

  props: {
    contactText: {
      type: String,
      required: true
    },

    email: {
      type: String,
      required: true
    },

    emailVisible: {
      type: Boolean,
      default: false
    },

    iconSavedUrl: {
      type: String,
      required: true,
    },

    iconUnsavedUrl: {
      type: String,
      required: true,
    },

    id: {
      type: String,
      required: true
    },

    presaved: {
      type: Boolean,
      required: true
    },

    saveable: {
      type: Boolean,
      required: true
    },

    saveAction: {
      type: String,
      required: true
    },

    saveData: {
      type: Object,
      default: () => ({})
    },

    userCreatedAt: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      saved: undefined,
    }
  },

  computed: {
    contactLink () {
      const email = this.email

      return `mailto:${email}`
    },

    saveButtonIconUrl () {
      return this.saved ? this.iconSavedUrl : this.iconUnsavedUrl
    },

    userCreatedAtText () {
      const formattedDate = moment(this.userCreatedAt).locale('en-gb').format('L')

      return 'Added ' + formattedDate
    }
  },

  created () {
    setAxiosHeaders(axios)
    this.saved = this.presaved
  },


  methods: {
    toggleSavedState () {
      const action = this.saveAction

      const newSavedState = !this.saved
      const method = newSavedState ? 'post' : 'delete'

      const data = this.saveData

      axios(action, { method, data })
        .then(() => this.saved = newSavedState)
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>