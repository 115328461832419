var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-listing"},[_c('ProjectListingHero',_vm._b({},'ProjectListingHero',{
      developerName: _vm.config.developerName,
      developerProjects: _vm.config.developerProjects,
      iconArrowGreyLeftUrl: _vm.iconArrowGreyLeftUrl,
      iconDeveloperUrl: _vm.iconDeveloperUrl,
      id: _vm.config.id
    },false)),_vm._v(" "),_c('ListingMain',_vm._b({},'ListingMain',{
      actionBarProps: {
        contactText: _vm.config.contactText,
        email: _vm.config.email,
        emailVisible: _vm.config.emailVisible,
        iconSavedUrl: _vm.iconSavedUrl,
        iconUnsavedUrl: _vm.iconUnsavedUrl,
        id: _vm.config.id,
        presaved: _vm.config.presaved,
        saveable: _vm.config.saveable,
        saveAction: _vm.config.saveAction,
        saveData: _vm.config.saveData,
        userCreatedAt: _vm.config.userCreatedAt
      },
      dataMainSections: _vm.config.profileSections,
      iconDownloadUrl: _vm.iconDownloadUrl,
      iconExternalLinkUrl: _vm.iconExternalLinkUrl
    },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }