<template>
  <div>
    <h2
      class="explainer__title"
      v-html="title"
    />
    <div
      class="explainer__text"
      v-html="text"
    />
  </div>
</template>

<script>

export default {
  name: 'Explainer',

  props: {
    title: {
      type: String,
      default: undefined
    },
    
    text: {
      type: String,
      default: undefined
    }
  }
}
</script>