<template>
  <div class="listing-main">
    <div class="listing-main__inner">
      <ListingActionBar
        v-bind="{ ...actionBarProps }"
      />
      <div class="listing-data">
        <div class="listing-data__side">
          <slot name="dataSide" />
        </div>
        <ListingDataMain
          v-bind="{
            iconDownloadUrl,
            iconExternalLinkUrl,
            sections: dataMainSections
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ListingActionBar from './ActionBar'
import ListingDataMain from './DataMain'

export default {
  name: 'ListingMain',

  components: {
    ListingActionBar,
    ListingDataMain
  },

  props: {
    actionBarProps: {
      type: Object,
      default: () => ({})
    },

    dataMainSections: {
      type: Array,
      default: () => []
    },

    iconDownloadUrl: {
      type: String,
      default: ''
    },

    iconExternalLinkUrl: {
      type: String,
      default: ''
    }
  }
}
</script>