var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"buyer-profile-listing"},[_c('BuyerProfileListingHero',_vm._b({},'BuyerProfileListingHero',{
      iconArrowGreyLeftUrl: _vm.iconArrowGreyLeftUrl,
      iconBuyerUrl: _vm.iconBuyerUrl,
      name: _vm.config.name
    },false)),_vm._v(" "),_c('ListingMain',_vm._b({scopedSlots:_vm._u([{key:"dataSide",fn:function(){return [_c('div',{staticClass:"listing-data__side-item"},[_c('h4',[_vm._v("Company name")]),_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(_vm.config.companyName)}})]),_vm._v(" "),(_vm.config.interests.length)?_c('div',{staticClass:"listing-data__side-item"},[_c('h4',[_vm._v("Interests")]),_vm._v(" "),_c('ul',_vm._l((_vm.config.interests),function(interest,interestIndex){return _c('li',{key:interestIndex,domProps:{"textContent":_vm._s(_vm.interestItemText(interest))}})}),0)]):_vm._e()]},proxy:true}])},'ListingMain',{
      actionBarProps: {
        contactText: _vm.config.contactText,
        email: _vm.config.email,
        emailVisible: _vm.config.emailVisible,
        iconSavedUrl: _vm.iconSavedUrl,
        iconUnsavedUrl: _vm.iconUnsavedUrl,
        id: _vm.config.id,
        presaved: _vm.config.presaved,
        saveable: _vm.config.saveable,
        saveAction: _vm.config.saveAction,
        saveData: _vm.config.saveData,
        userCreatedAt: _vm.config.userCreatedAt
      },
      dataMainSections: _vm.config.profileSections
    },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }