<template>
  <div class="form-error-banner">
    <span v-text="errorText" />
  </div>
</template>

<script>
export default {
  name: 'FormErrorBanner',
  props: {
    errorText: {
      type: String,
      required: true
    }
  }
}
</script>