<template>
  <div class="listing-download">
    <span
      class="listing-download__text"
      v-text="item.text"
    />
    <a
      v-if="hasLink"
      class="listing-download__link"
      :href="item.link"
    >
      <span class="listing-download__link-text">
        Download
      </span>
      <img
        class="listing-download__link-icon"
        :src="iconDownloadUrl"
      >
    </a>
  </div>
</template>

<script>
export default {
  name: 'ListingListDownload',

  props: {
    iconDownloadUrl: {
      type: String,
      required: true
    },

    item: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    hasLink () {
      return !!this.item.link
    }
  }
}
</script>