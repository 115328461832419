<template>
  <div class="loading-spinner__background">
    <div
      functional
      class="loading-spinner__spinner"
    >
      <div class="loading-spinner__inner">
        <div class="loading-spinner__segment" />
        <div class="loading-spinner__segment" />
        <div class="loading-spinner__segment" />
        <div class="loading-spinner__segment" />
        <div class="loading-spinner__segment" />
        <div class="loading-spinner__segment" />
        <div class="loading-spinner__segment" />
        <div class="loading-spinner__segment" />
        <div class="loading-spinner__segment" />
        <div class="loading-spinner__segment" />
        <div class="loading-spinner__segment" />
        <div class="loading-spinner__segment" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormUploadingIndicator'
}
</script>
