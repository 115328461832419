<template>
  <div class="project-listing">
    <ProjectListingHero
      v-bind="{
        developerName: config.developerName,
        developerProjects: config.developerProjects,
        iconArrowGreyLeftUrl,
        iconDeveloperUrl,
        id: config.id
      }"
    />
    <ListingMain
      v-bind="{
        actionBarProps: {
          contactText: config.contactText,
          email: config.email,
          emailVisible: config.emailVisible,
          iconSavedUrl,
          iconUnsavedUrl,
          id: config.id,
          presaved: config.presaved,
          saveable: config.saveable,
          saveAction: config.saveAction,
          saveData: config.saveData,
          userCreatedAt: config.userCreatedAt
        },
        dataMainSections: config.profileSections,
        iconDownloadUrl,
        iconExternalLinkUrl
      }"
    />
  </div>
</template>

<script>
import ProjectListingHero from './listing/Hero'
import ListingMain from '../listing/Main'

export default {
  name: 'ProjectListing',

  components: {
    ProjectListingHero,
    ListingMain,
  },

  props: {
    config: {
      type: Object,
      required: true
    },

    iconArrowGreyLeftUrl: {
      type: String,
      required: true,
    },

    iconDeveloperUrl: {
      type: String,
      required: true,
    },

    iconDownloadUrl: {
      type: String,
      default: ''
    },

    iconExternalLinkUrl: {
      type: String,
      default: ''
    },

    iconSavedUrl: {
      type: String,
      required: true,
    },

    iconUnsavedUrl: {
      type: String,
      required: true,
    }
  }
}
</script>