<template>
  <div>
    <FormHero v-bind="{ heading: config.heroHeading }" />

    <Survey
      :survey="survey"
      class="form-buyer-profile"
    />

    <FormBasicActionBar v-bind="{ save }" />

    <FormErrorBanner
      v-if="hasErrors"
      v-bind="{ errorText }"
    />
  </div>
</template>

<script>
// SurveyJS: imports
import 'survey-vue/modern.min.css'
import { Model, StylesManager, Survey } from 'survey-vue'

// SurveyJS: set theme
StylesManager.applyTheme('modern')

// Axios
import axios from 'axios'
import { setAxiosHeaders } from '../../helpers/setAxiosHeaders'

// custom SurveyJS form helpers
import {
  retainNullValues,
  setExistingNestedAttributes,
  setExistingOtherData,
  submit
} from '../../helpers/formHelpers'

// child components
import FormBasicActionBar from '../form/BasicActionBar'
import FormErrorBanner from '../form/ErrorBanner'
import FormHero from '../form/Hero'

export default {
  name: 'BuyerProfileSurvey',

  components: {
    FormBasicActionBar,
    FormErrorBanner,
    FormHero,
    Survey
  },

  props: {
    config: {
      type: Object,
      default: () => ({})
    },

    errorText: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      hasErrors: false,
      survey: new Model(this.config.survey)
    }
  },

  created () {
    setAxiosHeaders(axios)
    setExistingOtherData(this.survey, this.config)
    setExistingNestedAttributes(this.survey, this.config)

    this.survey.onValueChanged.add((sender, options) => {
      retainNullValues(this.survey, options, this.config)
    })
  },

  methods: {
    handleErrors (error) {
      console.error(error)
      this.hasErrors = true
    },

    save () {
      this.hasErrors = false
      submit(this.survey.data, this.config, axios, null, this.handleErrors)
    }
  }
}
</script>