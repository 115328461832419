<template>
  <div class="listing-external-link">
    <span
      class="listing-external-link__text"
      v-text="item.text"
    />
    <a
      class="listing-external-link__link"
      :href="item.link"
      target="_blank"
    >
      <span class="listing-external-link__link-text">
        View
      </span>
      <img
        class="listing-external-link__link-icon"
        :src="iconExternalLinkUrl"
      >
    </a>
  </div>
</template>

<script>
export default {
  name: 'ListingListExternalLink',

  props: {
    iconExternalLinkUrl: {
      type: String,
      required: true
    },

    item: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>