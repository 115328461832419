<template>
  <ul class="listing-list">
    <li
      v-for="(item, itemIndex) in items"
      :key="itemIndex"
      class="listing-list__item"
    >
      <ListingListDownload
        v-if="hasDownloadLinks"
        v-bind="{ iconDownloadUrl, item }"
      />
      <ListingListExternalLink
        v-else-if="hasExternalLinks"
        v-bind="{ iconExternalLinkUrl, item }"
      />
      <span
        v-else
        v-text="item"
      />
    </li>
  </ul>
</template>

<script>
import ListingListDownload from './list/Download'
import ListingListExternalLink from './list/ExternalLink'

export default {
  name: 'ListingList',

  components: {
    ListingListDownload,
    ListingListExternalLink
  },

  props: {
    hasDownloadLinks: {
      type: Boolean,
      default: false
    },

    hasExternalLinks: {
      type: Boolean,
      default: false
    },

    iconDownloadUrl: {
      type: String,
      default: ''
    },

    iconExternalLinkUrl: {
      type: String,
      default: ''
    },

    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>