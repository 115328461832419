<template>
  <Tabs>
    <slot name="tables" />
  </Tabs>
</template>


<script>
import Tabs from 'components/Tabs.vue'

export default {
  name: 'Dashboard',

  components: {
    Tabs
  },
}
</script>


<style lang="sass">
// Breaking with convention in this project to provide components styles directly here.

// The reason for this is that the filterableTable component handles a lot of its own styles internally using its options prop. That method of applying styles should be used everywhere possible, and the styles here are intended only for specific overrides not possible through the `options` prop.

.filters,
.table-body,
.table-head {
  font-weight: 400;
}

.filters .filter__button-download {
  display: none;
}

.filter > .trigger,
.filter__button-download > .legend__button {
  border: 0.031rem solid #707070; // 0.5px
  border-radius: 0.188rem; // 3px
}

.table-head {
  .cell {
    background-color: #2e2e2e;
    border-left: none;
    height: auto;
    padding: 1.563rem 0.938rem; // 25px 15px

    align-items: normal;

    .title,
    .sorting-toggle {
      display: flex;
      align-items: center;
    }
  }
}

.table-body {
  color: #272727;
  line-height: 1.625rem; // 26px
  font-weight: 400;

// Separate rows vertically
  & > .row {
    margin-top: 0.313rem; // 5px
  }

  .cell {
    background-color: #ffffff;
    border-left: none;
    padding: 0.938rem; // 15px
  }
}

</style>