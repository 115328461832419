// Libraries
import Vue from 'vue'
import FilterableTable from '@unep-wcmc/wcmc-components'
import TurbolinksAdapter from 'vue-turbolinks'
import Turbolinks from 'turbolinks'
import { BootstrapVue } from 'bootstrap-vue'

// Store
import store from '../store'

Turbolinks.start()
Vue.config.productionTip = false

Vue.use(TurbolinksAdapter)
Vue.use(BootstrapVue) // Used for Vue Bootstrap toggle component
Vue.use(FilterableTable, { store })

// Components
import Dashboard from 'components/Dashboard'
import BuyerProfileListing from 'components/buyerProfile/Listing'
import BuyerProfileSurvey from 'components/buyerProfile/Survey'
import Explainer from 'components/Explainer'
import ProjectListing from 'components/project/Listing'
import ProjectSurvey from 'components/project/Survey'
import Tab from 'components/Tab'
import Tabs from 'components/Tabs'
import TopbarMenu from 'components/TopbarMenu'
import UserSurvey from '../components/user/Survey'

Turbolinks.start()

document.addEventListener('turbolinks:load', () => {
  // TODO: Make sure to track GA page views manually in here otherwise you'll miss some!
  // See ENCORE example.
  if (document.getElementById('v-app')) {
    Vue.prototype.$eventHub = new Vue()
    Vue.prototype.$store = store

    window.app = new Vue({
      el: '#v-app',

      components: {
        BuyerProfileListing,
        BuyerProfileSurvey,
        Dashboard,
        Explainer,
        ProjectListing,
        ProjectSurvey,
        Tab,
        Tabs,
        TopbarMenu,
        UserSurvey
      }
    })
  }
})
