<template>
  <div class="form-action-bar">
    <div class="form-action-bar__side">
      &nbsp;
    </div>
    <div class="form-action-bar__side form-action-bar__side--right">
      <button
        class="form-action-bar__item form-action-bar__item--save"
        @click.prevent="save"
        v-text="saveText"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormBasicActionBar',

  props: {
    save: {
      type: Function,
      required: true
    },

    saveText: {
      type: String,
      default: 'Save'
    }
  }
}
</script>