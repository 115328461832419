<template>
  <div>
    <UserSignUpHero
      v-if="userSurveyConfig.newUser"
      v-bind="{
        imageUrl: heroImageUrl,
        signInPath,
        subtitle,
        title
      }"
    />
    <FormHero
      v-else
      v-bind="{ heading: userSurveyConfig.heroHeading }"
    />

    <div
      v-if="userSurveyConfig.newUser"
      class="sign-up__user-types"
    >
      <p class="sign-up__current-user-type">
        I'm {{ indefiniteArticle(selectedRole) }} {{ selectedRole }}
      </p>

      <p class="sign-up__alt-user-types">
        or sign up as {{ indefiniteArticle(alternativeRoles[0]) }}
        <a
          :href="getAlternativeRoleLink(alternativeRoles[0])"
          class="sign-up__alt-user-type-link"
          v-text="alternativeRoles[0]"
        />
        <!--
          commented out for now until investors are implemented

          or {{ indefiniteArticle(alternativeRoles[1]) }}
          <a
            :href="getAlternativeRoleLink(alternativeRoles[1])"
            class="sign-up__alt-user-type-link"
            v-text="alternativeRoles[1]"
          />
        -->
      </p>
    </div>

    <Survey
      :survey="survey"
      class="form-user"
      :class="{ 'form-user--new': userSurveyConfig.newUser }"
    />

    <div class="form-user-save">
      <div class="form-user-save__inner">
        <button
          class="form-user-save__button"
          @click.prevent="save"
          v-text="userSurveyConfig.saveText"
        />
      </div>
    </div>

    <FormErrorBanner
      v-if="hasErrors"
      v-bind="{ errorText }"
    />
  </div>
</template>

<script>
// SurveyJS
import 'survey-vue/modern.min.css'
import { Survey, StylesManager, Model } from 'survey-vue'

// Axios
import axios from 'axios'
import { setAxiosHeaders } from '../../helpers/setAxiosHeaders'

// Components
import FormErrorBanner from '../form/ErrorBanner'
import FormHero from '../form/Hero'
import UserSignUpHero from './SignUpHero'

// Constants
import { roleEnumerableMap, roleArray as roles} from '../../constants/roles.js'

StylesManager.applyTheme('modern')

export default {
  name: 'UserSurvey',

  components: {
    FormErrorBanner,
    FormHero,
    Survey,
    UserSignUpHero
  },

  props: {
    errorText: {
      type: String,
      default: ''
    },

    heroImageUrl: {
      type: String,
      required: true
    },

    selectedRole: {
      type: String,
      default: ''
    },

    subtitle: {
      type: String,
      default: ''
    },

    title: {
      type: String,
      default: ''
    },

    userSurveyConfig: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      hasErrors: false,
      survey: new Model(this.userSurveyConfig.survey)
    }
  },

  computed: {
    alternativeRoles () {
      return roles.filter(role => role !== this.selectedRole)
    },

    roleAsEnumerable () {
      return roleEnumerableMap[this.selectedRole]
    }
  },

  mounted () {
    setAxiosHeaders(axios)
  },

  methods: {
    getAlternativeRoleLink (role) {
      return `/users/sign_up?role=${role.toLowerCase()}`
    },

    indefiniteArticle (role) {
      return role === 'investor' ? 'an' : 'a'
    },

    redirectToDashboard () {
      window.location = '/dashboard'
    },

    save () {
      const { action, key, method } = this.userSurveyConfig.axios

      const userData = { 
        ...this.survey.data,
        company_attributes: this.survey.data.company_attributes[0]
      }

      if (this.userSurveyConfig.newUser) {
        userData.role = this.roleAsEnumerable
      }

      const data = { [key]: userData }

      this.hasErrors = false

      // update to use axios passed in via config
      axios(action, { method, data })
        .then(this.redirectToDashboard)
        .catch(() => this.hasErrors = true)
    }
  },
}
</script>
