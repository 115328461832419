<template>
  <div class="form-hero">
    <div class="form-hero__inner">
      <h1
        class="form-hero__heading"
        v-text="heading"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormHero',

  props: {
    heading: {
      type: String,
      required: true
    }
  }
}
</script>