<template>
  <div>
    <FormHero v-bind="{ heading: config.heroHeading }" />
    <Survey
      v-bind="{ survey }"
      class="form-project"
    />
    <ProjectSurveyActionBar
      v-bind="{
        isFirstPage,
        isLastPage,
        nextPage,
        previousPage,
        publish,
        saveAndExit
      }"
    />
    <FormUploadingIndicator v-if="uploadingFiles" />
    <FormErrorBanner
      v-if="hasErrors"
      v-bind="{ errorText }"
    />
  </div>
</template>

<script>
// SurveyJS: general imports
import 'survey-vue/modern.min.css'
import * as SurveyVue from 'survey-vue'

// SurveyJS: register custom properties
SurveyVue.Serializer.addProperty('question', 'tooltip:text')

// SurveyJS: register tagbox input for searchable multiselect (e.g. for countries)
import * as widgets from 'surveyjs-widgets'
widgets.select2tagbox(SurveyVue)

// SurveyJS: destructure from SurveyVue after registering tagbox
// - Model: config/data etc
// - StylesManager: theme setter
// - Survey: component
const { Model, StylesManager, Survey } = SurveyVue

// SurveyJS: set theme
StylesManager.applyTheme('modern')

// Axios
import axios from 'axios'
import { setAxiosHeaders } from '../../helpers/setAxiosHeaders'

// custom SurveyJS form helpers
import {
  processFileUpload,
  processFileRemoval,
  removeFileOnQuestionHidden,
  retainNullValues,
  setExistingNestedAttributes,
  setExistingOtherData,
  setExistingUploadedFiles,
  submit
} from '../../helpers/formHelpers'

// child components
import FormErrorBanner from '../form/ErrorBanner'
import FormHero from '../form/Hero'
import FormUploadingIndicator from '../form/UploadingIndicator'
import ProjectSurveyActionBar from './SurveyActionBar'

export default {
  name: 'ProjectSurvey',

  components: {
    FormErrorBanner,
    FormHero,
    FormUploadingIndicator,
    ProjectSurveyActionBar,
    Survey
  },

  props: {
    config: {
      type: Object,
      default: () => ({})
    },

    errorText: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      hasErrors: false,
      isFirstPage: true,
      isLastPage: false,
      survey: new Model(this.config.survey),
      uploadedFiles: {},
      uploadingFiles: false
    }
  },

  created () {
    setAxiosHeaders(axios)
    setExistingOtherData(this.survey, this.config)
    setExistingNestedAttributes(this.survey, this.config)
    setExistingUploadedFiles(this, this.config)

    this.survey.onAfterRenderQuestion.add((sender, options) => {
      this.addTooltip(options)
    })

    this.survey.onClearFiles.add((sender, options) => {
      processFileRemoval(this, options)
    })

    this.survey.onCurrentPageChanged.add(() => {
      this.scrollToTop()
      this.updatePageData()
    })

    this.survey.onUploadFiles.add((sender, options) => {
      processFileUpload(this, options)
    })

    this.survey.onValueChanged.add((sender, options) => {
      retainNullValues(this.survey, options, this.config)
    })

    this.survey.onVisibleChanged.add((sender, options) => {
      removeFileOnQuestionHidden(this.survey, options)
    })
  },

  methods: {
    addTooltip (options) {
      const text = options.question.tooltip

      // return if the question has no tooltip text
      if (!text) return

      // create tooltip elements
      const container = document.createElement('div')
      const button = document.createElement('button')
      const tooltip = document.createElement('div')

      container.className = 'form__tooltip-trigger-container'
      button.className = 'form__tooltip-trigger'
      tooltip.className = 'form__tooltip'
      tooltip.innerHTML = text

      // get question heading
      const heading = options.htmlElement.querySelector('h5')

      // add elements
      container.appendChild(button)
      heading.appendChild(container)

      container.onclick = () =>
        container.lastChild === tooltip
          ? container.removeChild(tooltip)
          : container.appendChild(tooltip)
    },

    handleErrors (error) {
      console.error(error)
      this.hasErrors = true
    },

    nextPage () {
      this.survey.nextPage()
    },

    previousPage () {
      this.survey.prevPage()
    },

    publish () {
      const data = { ...this.survey.data, state: 'live' }

      this.hasErrors = false
      submit(data, this.config, axios, this.uploadedFiles, this.handleErrors)
    },

    saveAndExit () {
      this.hasErrors = false
      submit(this.survey.data, this.config, axios, this.uploadedFiles, this.handleErrors)
    },

    scrollToTop () {
      window.scrollTo(0, 0)
    },

    updatePageData () {
      this.isFirstPage = this.survey.isFirstPage
      this.isLastPage = this.survey.isLastPage
    }
  }
}
</script>
