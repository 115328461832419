<template>
  <div 
    v-show="isActive"
    class="tab"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      isActive: true
    }
  }
}
</script>
