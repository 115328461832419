<template>
  <div class="listing-data__main">
    <template v-for="(section, sectionIndex) in sections">
      <div
        v-if="section.elements.length"
        :key="sectionIndex"
      >
        <h2
          class="listing-data__main-heading"
          v-text="section.heading"
        />
        <template v-for="(element, elementIndex) in section.elements">
          <div
            :key="elementIndex"
            class="listing-data__main-item"
          >
            <h3
              v-if="element.heading"
              class="listing-data__main-item-heading"
              v-text="element.heading"
            />
            <ListingList
              v-if="element.type === 'list'"
              v-bind="{
                iconDownloadUrl,
                iconExternalLinkUrl,
                items: element.data,
                ...element.customProperties
              }"
            />
            <ListingTable
              v-else-if="element.type === 'table'"
              v-bind="{ ...element.data }"
            />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import ListingList from '../listing/List'
import ListingTable from '../listing/Table'

export default {
  name: 'ListingDataMain',

  components: {
    ListingList,
    ListingTable
  },

  props: {
    iconDownloadUrl: {
      type: String,
      default: ''
    },

    iconExternalLinkUrl: {
      type: String,
      default: ''
    },

    sections: {
      type: Array,
      default: () => []
    },
  }
}
</script>