<template>
  <div class="tabs__container">
    <ul class="tabs__list">
      <li
        v-for="(tab, index) in tabs"
        :key="tab.title"
        :class="getTabClass(index)"
        @click="selectTab(index)"
        v-text="tab.title"
      />
    </ul>

    <slot />
  </div>
</template>

<script>
export default {
  props: {},

  data () {
    return {
      selectedIndex: 0,
      tabs: []
    }
  },

  created () {
    this.tabs = this.$children
  },

  mounted () {
    this.selectTab(0)
  },

  methods: {
    selectTab (selectedIndex) {
      this.selectedIndex = selectedIndex

      // loop over all the tabs
      this.tabs.forEach((tab, index) => {
        tab.isActive = (index === selectedIndex)
      })
    },

    getTabClass (tabIndex) {
      if (tabIndex === this.selectedIndex) {
        return 'tabs__list-item--selected'
      } else {
        return 'tabs__list-item'
      }
    }
  },
}
</script>
