<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10.287 5.851"
  >
    <g
      id="New_Symbol_1"
      transform="translate(0.354 5.144) rotate(-90)"
    >
      <path
        id="Path_1"
        data-name="Path 1"
        d="M4.79,0,0,4.79,4.79,9.58"
        transform="translate(0 0)"
        fill="none"
        stroke="white"
        stroke-width="1"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ArrowDown',
}
</script>
