var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FormHero',_vm._b({},'FormHero',{ heading: _vm.config.heroHeading },false)),_vm._v(" "),_c('Survey',_vm._b({staticClass:"form-project"},'Survey',{ survey: _vm.survey },false)),_vm._v(" "),_c('ProjectSurveyActionBar',_vm._b({},'ProjectSurveyActionBar',{
      isFirstPage: _vm.isFirstPage,
      isLastPage: _vm.isLastPage,
      nextPage: _vm.nextPage,
      previousPage: _vm.previousPage,
      publish: _vm.publish,
      saveAndExit: _vm.saveAndExit
    },false)),_vm._v(" "),(_vm.uploadingFiles)?_c('FormUploadingIndicator'):_vm._e(),_vm._v(" "),(_vm.hasErrors)?_c('FormErrorBanner',_vm._b({},'FormErrorBanner',{ errorText: _vm.errorText },false)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }